<!--页面代码从这里开始拷贝-->
<template>
  <div>
    <table-compnent :records="tableData" @resetHandler="reset" @searchHandler="doSearch"
      @batchDeleteHandler="batchDelete" @addHandler="saveTripDriver" :loading="loading" :showDelete="false"
      :showAdd="false">
      <template v-slot:search>
        <el-form-item label="姓名:">
          <el-input v-model.trim="search.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input v-model.trim="search.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="状态:">
          <el-select v-model="search.accountApplyStatus" clearable placeholder="请选择状态">
            <el-option v-for="(item, index) in states" :key="index" :label="item.name" :value="item.no" />
          </el-select>
        </el-form-item>
        <el-form-item label="车牌号:">
          <el-input v-model.trim="search.carNumber" placeholder="请输入车牌号"></el-input>
        </el-form-item>

        <el-form-item label="所属公司:">
          <el-input v-model.trim="search.companyName" placeholder="请输入所属公司"></el-input>
        </el-form-item>
        <el-form-item label="商户号:">
          <el-input v-model.trim="search.huifuId" maxlength="5" placeholder="请输入商户号后五位查询"></el-input>
        </el-form-item>
        <el-form-item label="注册日期">
          <el-date-picker v-model="registerDate" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" @change="registerDateChange">
          </el-date-picker>
        </el-form-item>
      </template>
      <template v-slot:buttons>
        <el-button type="primary" :loading="exportSearchLoading" @click="exportDriverExl">导出查询结果</el-button>
      </template>
      <template v-slot:content>
        <el-table-column prop="name" label="姓名">
          <template scope="s">
                       
            <el-button type="text" @click="toDetail(s.row.no)">{{
                s.row.name
            }}</el-button>
                     
          </template>
        </el-table-column>
        <el-table-column prop="idCard" label="身份证号"></el-table-column>
        <el-table-column prop="carNumber" label="车牌号"></el-table-column>
        <el-table-column prop="companyName" label="所属公司"></el-table-column>
        <!-- <el-table-column prop="nativePlace" label="籍贯"></el-table-column> -->
        <!-- <el-table-column prop="divisionAge" label="司龄(年)"></el-table-column>
        <el-table-column prop="carType" label="准驾车型"></el-table-column> -->
        <el-table-column prop="motorcadeName" label="所属车队"></el-table-column>
        <el-table-column prop="mobile" label="手机号"></el-table-column>
        <el-table-column prop="huifuId" label="商户号"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ scope.row.accountApplyStatusName }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="注册时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | dateFormat }}
          </template>
        </el-table-column>
      </template>
    </table-compnent>
  </div>
</template>
  
  <script>
import TableCompnent from "@/components/table/TablesComponents";
import TripDriverApi from "@/api/TripDriverApi";
import { navTo, queryParam } from "@/utils/NavigatorUtils";
import { CommonDateFormat } from "@/utils/DateUtils";
export default {
  name: "TripDriverList",
  props: {
    msg: String,
  },
  components: {
    TableCompnent,
  },
  data() {
    return {
      tableData: {},
      registerDate: [],
      search: {
        name: "",
        mobile: "",
        carNumber: "",
        companyName: "",
        huifuId: "",
        startTimeStr: "",
        endTimeStr: "",
        accountApplyStatus: null,
      },
      page: {},
      loading: true,
      exportSearchLoading: false,
    };
  },
  filters: {
    dateFormat(val) {
      if (isNaN(val)) return "";
      return CommonDateFormat(new Date(val));
    },
  },
  created() {
    this.findStates();
  },
  methods: {
    toDetail(no) {
      navTo(this, `/TripDriverDetail/${no}`);
      // this.$router.push({
      //   path: "/TripDriverDetail",
      //   params: {
      //     id,
      //   },
      // });
    },
    reset() {
      this.search = this.$options.data().search;
      this.registerDate=[];
      this.doSearch({ pageSize: 20, pageNum: 1 });
    },
    doSearch(params) {
      this.loading = true;
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      this.search.accountApplyStatus =
        this.search.accountApplyStatus == ""
          ? null
          : this.search.accountApplyStatus;
      TripDriverApi.search(this.search).then((resp) => {
        if (resp.code === "200") {
          console.log(resp.data);
          this.tableData = resp.data;
          this.loading = false;
        }
      });
    },
    saveTripDriver() {
      navTo(this, "/saveTripDriver");
    },
    queryById(id) {
      TripDriverApi.queryTripDriverById({ id: id }).then((resp) => {
        console.log(resp);
        if (resp.code === "200") {
          queryParam(this, "saveTripDriver", resp.data);
        }
      });
    },
    deleteById(id) {
      TripDriverApi.deleteTripDriver({ id: id }).then((resp) => {
        if (resp.code === "200") {
          this.doSearch(this.page);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    batchDelete(params) {
      TripDriverApi.batchDeleteTripDriver(params).then((resp) => {
        if (resp.code === "200") {
          this.$successMsg(resp.msg);
          this.doSearch(this.page);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    registerDateChange(date) {
      if (date) {
        this.search.startTimeStr = CommonDateFormat(
          new Date(date[0]),
          "YYYY-mm-dd"
        );
        this.search.endTimeStr = CommonDateFormat(
          new Date(date[1]),
          "YYYY-mm-dd"
        );
      } else {
        this.search.startTimeStr = "";
        this.search.endTimeStr = "";
      }
    },
    findStates() {
      TripDriverApi.findAccountApplyStatusSelect()
        .then((res) => {
          console.log(res);
          if (res.code == "200") {
            this.states = res.data;
          } else {
            this.$errorMsg(res.msg);
          }
        })
        .catch((err) => {
          this.$errorMsg(err);
        });
    },
    exportDriverExl() {
      let that = this;
      if (this.exportSearchLoading) {
        return;
      }
      this.exportSearchLoading = true;
      TripDriverApi.exportDriverList(this.search)
        .then((res) => {
          this.exportSearchLoading = false;
          if (res.status == 200) {
            let blob = new Blob([res.data]);
            let cd =
              res.headers["Content-Disposition"] ||
              res.headers["content-disposition"];
            if (cd == undefined || cd == null) {
              let reader = new FileReader();
              reader.addEventListener("loadend", function () {
                let resp = JSON.parse(reader.result);
                that.$errorMsg(resp.msg);
              });
              reader.readAsText(blob, "utf-8");
              return;
            }
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            let fileName = decodeURIComponent(
              cd.replace("attachment; filename=", "")
            );
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            this.$successMsg("导出成功");
          } else {
            this.$errorMsg(res.msg);
          }
        })
        .catch((err) => {
          this.$errorMsg(err);
          this.exportSearchLoading = false;
        });
    },
  },
};
</script>
  <!--页面代码从这里结束拷贝-->